import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import experienceData from "./experienceData";

const Experience = () => {
    return (
        <div className="resume-box">
        <h2 className="resume-header">Experience</h2>
            {experienceData.map(item => (
                <ul key={item.company}>
                    <li>
                        <div className="icon">
                            <FontAwesomeIcon icon={faBriefcase} />
                        </div>
                        <span className="time">{item.start} - {item.end ? item.end : 'Present'}</span>
                        <h5>{item.position} - {item.company}</h5>
                        <p>
                            {item.description}
                        </p>
                        <p className="skills-used"><strong>Skills used:{" "}</strong>{item.techUsed.join(', ')}</p>
                    </li>
                </ul>
            ))}
        </div>
    );
};

export default Experience;
