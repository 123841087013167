import {faReact } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NodeIcon from './icons/nodejs.1024x627.png'
import MuiIcon from './icons/mui.png'
import Express from './icons/express.png'
import Mongo from './icons/mongodb.png'
import Postgress from './icons/elephant.png'
import Next from './icons/nextjs.png'
import AWS from './icons/aws.png'
import Docker from './icons/docker.png'
import Redis from './icons/redis.png'
import Tailwind from './icons/tailwind.png'
import Bootstrap from './icons/bootstrap.png'


const skillsData = [
    {
        icon:<FontAwesomeIcon icon={faReact} color='#149ECA'/>,
        skill:"React",
        type:'icon'
    },
    {
        icon:NodeIcon,
        skill:"Node",
        type:"image"
    },
    {
        icon:Express,
        skill:"Express",
        type:"image"
    },
    {
        icon:Next,
        skill:"NextJs",
        type:"image"
    },
    {
        icon:Tailwind,
        skill:"Tailwind",
        type:"image"
    },
    {
        icon:Bootstrap,
        skill:"Bootstrap",
        type:"image"
    },
    {
        icon:MuiIcon,
        skill:"MUI",
        type:"image"
    },
    {
        icon:Mongo,
        skill:"MongoDB",
        type:"image"
    },
    {
        icon:Postgress,
        skill:"Postgress",
        type:"image"
    },
    {
        icon:Docker,
        skill:"Docker",
        type:"image"
    },
    {
        icon:Redis,
        skill:"Redis",
        type:"image"
    },
    {
        icon:AWS,
        skill:"AWS",
        type:"image"
    }
]

export default skillsData