import React from 'react'
import Carousel from 'better-react-carousel'

const ImageCarousel = ({images}) => {
  return (
    <Carousel cols={2} rows={1} gap={15} loop>
    {
      images && images.map(image=><Carousel.Item>
        <img width="100%" src={image} />
      </Carousel.Item>)
    }
      {/* <Carousel.Item>
        <img width="100%" src="https://picsum.photos/800/600?random=1" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="https://picsum.photos/800/600?random=2" />
      </Carousel.Item>
      <Carousel.Item>
        <img width="100%" src="https://picsum.photos/800/600?random=3" />
      </Carousel.Item>
      <Carousel.Item>
        {/* anything you want to show in the grid 
      </Carousel.Item> */}
      {/* ... */}
    </Carousel>
  )
}

export default ImageCarousel