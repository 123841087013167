import React from "react";

import Project from "./project";


import "./styles/allProjects.css";
import data from "./data";

const AllProjects = () => {
	return (
		<>
			<h2>Projects</h2>
			<div className="all-projects-container">
			{Object.entries(data).map(([key,value])=>(
				<div className="all-projects-project" key={key}>
					<Project
						logo={value.logo}
						title={key}
						description={value.desc}
						linkText={value.linkText}
						link={value.link}
					/>
				</div>
			))}

		</div>
		</>
		
	);
};

export default AllProjects;
