import React, { useEffect, useRef, useState } from "react";
import "./styles/projectsData.css";
import ImageCarousel from "./imageCrouse";
import { useParams } from "react-router-dom";
import data from "./data";

const ProjectsData = () => {
	const { viewProject } = useParams();
	const [project, setProject] = useState({});
	useEffect(() => {
		setProject(data[viewProject]);
	}, [project, viewProject]);

	if (!viewProject) {
		return (
			<>
				{Object.entries(data).map(([key, value]) => (
					<div className="project-wrapper">
						<h1>{key.toLocaleUpperCase()}</h1>
						{Object.entries(value).map(([key, value]) =>
							key === "desc" ||
							key === "logo" ||
							key === "linkText" ||
							key === "images" ||
							key === "link" ? (
								<></>
							) : (
								<>
									<div className="project-content">
										<div>
											<strong>{key}:</strong>&nbsp;<span className="content-text">{value}</span>
										</div>
									</div>
								</>
							)
						)}
						{
							data[key].images && <ImageCarousel images={data[key].images}/>
						}
						
					</div>
				))}
			</>
		);
	}

	return (
		<>
			<div className="project-wrapper">
				<h1>{viewProject.toLocaleUpperCase()}</h1>
				{Object.entries(project).map(([key, value]) =>
					key === "desc" ||
					key === "logo" ||
					key === "linkText" ||
					key === "images" ||
					key === "link" ? (
						<></>
					) : (
						<>
							<div className="project-content">
								<div>
									<strong>{key}:</strong>&nbsp;<span className="content-text">{value}</span>
								</div>
							</div>
						</>
					)
				)}
				{data[viewProject].images && <ImageCarousel images={data[viewProject].images}/>}
			</div>
		</>
	);
};

export default ProjectsData;
