import driverSahaab1 from "../../assets/driverSahaab/1.jpeg";
import driverSahaab2 from "../../assets/driverSahaab/2.jpeg";
import driverSahaab3 from "../../assets/driverSahaab/3.jpeg";
import driverSahaab4 from "../../assets/driverSahaab/4.jpeg";
import driverSahaab5 from "../../assets/driverSahaab/5.jpeg";

import attendance1 from "../../assets/attendance/1.png";
import attendance2 from "../../assets/attendance/2.png";
import attendance3 from "../../assets/attendance/3.png";
import attendance4 from "../../assets/attendance/4.png";
import attendance5 from "../../assets/attendance/5.png";
import attendance6 from "../../assets/attendance/6.png";
import attendance7 from "../../assets/attendance/7.png";
import attendance8 from "../../assets/attendance/8.png";
import attendance9 from "../../assets/attendance/9.png";
import attendance10 from "../../assets/attendance/10.png";
import attendance11 from "../../assets/attendance/11.png";
import attendance12 from "../../assets/attendance/12.png";
import attendance13 from "../../assets/attendance/13.png";
import attendance14 from "../../assets/attendance/14.png";


import videoApp1 from '../../assets/videoApp/1.png'
import videoApp3 from '../../assets/videoApp/3.png'
import videoApp2 from '../../assets/videoApp/2.png'


const data = {
	"attendance management": {
		Goal: `Create attendance system
        for organization with diffrent sites/branches, provide
        better UI, attendance management and use
        face-recognition for attendance.`,

		Architecture: `Consists of two
        application admin and employee app resp. and a hardware
        device for attendance.`,

		"Admin-app": `This app is designed
        for administrators who have control over everything.
        They can create new sites, add devices, and include
        employees. Admins also have the power to approve
        employee face-images and assign managers to different
        sites. Additionally, they can manage employee leave
        requests.`,

		"Employee-app": `App for both regular
        employees and managers/leaders to track there attendace,
        upload face images which will later be used for facial
        recognition, able to deactivate there profile, apply for
        leave. the leader/manager have extra privilages such as
        they can mark attendace of employee, approve there
        leave, approve face-images.`,

		"Hardware-device": `Raspberry-pi
        hardware which runs opencv with pretrained facial
        recognition models to mark the attendance of the
        employee.`,

		Contribution: `Created project from
        scratch, developed both frontend and backend from start
        to finish, converted both admin/employee web apps to
        android app and optimized them for native apps like
        experience, created architecture for hardware and later
        implemented both software and hardware on the raspberry
        pi which includes opencv, bash scripts on software side
        and adding screen module, touch module, camera module,
        GPIO pins and other sensors on hardware side.`,

		"Tech used": `HTML, CSS, JavaScript,
        React.js, Material UI, Koa.js, Strapi, Mongodb, Nodejs.`,
		logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		linkText: "View Code",
		link: "#",

		desc: `Full stack Attendance management system with facial recognition system with Raspberry pi.`,
		images:[attendance1, attendance2, attendance3, attendance4,attendance5, attendance6, attendance7, attendance8, attendance9,attendance10, attendance11, attendance12, attendance13,attendance14]
	},

	"driver sahaab": {
		Goal: `Convert existing HTML/CSS website to React web application for client`,
		contribution: `I structured the project tasks to ensure we meet our deadline. Additionally, I mentored and assisted two interns, guiding them in their professional growth throughout the project.`,
		"Tech used": `React, Mui`,
		desc: "Converted HTML/CSS frontend into react web-application.",
		logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		linkText: "View Code",
		link: "https://github.com/godiyalashish/drivershaab",
		images: [
			driverSahaab1,
			driverSahaab2,
			driverSahaab3,
			driverSahaab4,
			driverSahaab5,

		],
	},

	"Food villa": {
		Goal: `Create Food ordering cart-system where user can add items to the cart`,

		Contribution: `Created highly scalable react-app from scratch using parcel as bundler, redux as a state Mangement library, and tailwind. optimised app by implementing lazy-loading, implemented search functionality, created cart functionality where user can add or remove items from cart, used swiggy’s live data.
Wrote test-cases from scratch in Unit testing and Integration testing.`,

		"Tech-used": `HTML, CSS, JavaScript, React.js, Redux, Tailwind, Jest, React-testing-library, react-router-dom.`,
		desc: `Created highly scalable food-app from scratch using parcel as bundler, redux as a state Mangement library.`,
		logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		linkText: "View Code",
		link: "https://github.com/godiyalashish/foodvilla",
	},

	"Video streaming app": {
		Goal: `Create a video streaming app using youtube's api.`,

		Contribution: `Implemented some of functionality of youtube using Youtube’s API, implemented debouncing in search suggestions, used caching for search optimization,  created n-level next comments, live chat feature.
Used Redux as a state-management library.`,

		"Tech-used": `HTML, CSS, JavaScript, React.js, Redux, Tailwind.`,
		desc: "Created video streaming application using React",
		logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
		linkText: "View Code",
		link: "https://github.com/godiyalashish/videoApp",
		images:[videoApp1, videoApp2, videoApp3]
	},
};

export default data;
