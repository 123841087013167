const educationData = [
    {
        degree:"Bachelor of technology in information technology",
        start:"2018",
        end:"2022",
        institute:"Hemwati nandan bahuguna garhwal university"
    }
]


export default educationData