import React from "react";
import "../experience/styles.css";
import "./styles.css";
import skillsData from "./skillsData";

const Skills = () => {
	return (
		<div className="resume-box">
            <h2 className="resume-header">Skills</h2>
			<div className="skills-box">
				{skillsData.map((item) => (
					<div className="skill">
                        {item.type === "icon" ? <div className="skill-icon">{item.icon}</div> : <div className="skill-icon"><img src={item.icon} alt={item.skill}/></div> }
                        <p className="skill-text"><strong>{item.skill}</strong></p>
                    </div>
                    )
				)}
			</div>
		</div>
	);
};

export default Skills;
