import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../experience/styles.css";
import { faBriefcase, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import educationData from "./educationData.js";

const Education = () => {
    return (
        <div className="resume-box">
        <h2 className="resume-header">Education</h2>
            {educationData.map(item => (
                <ul key={item.company}>
                    <li>
                        <div className="icon">
                        <FontAwesomeIcon icon={faUserGraduate} />
                        </div>
                        <span className="time">{item.start} - {item.end ? item.end : 'Present'}</span>
                        <h5>{item.degree}</h5>
                        <p>
                            <strong>Institute:</strong>{" " + item.institute}
                        </p>
                    </li>
                </ul>
            ))}
        </div>
    );
};

export default Education;
