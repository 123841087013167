const INFO = {
	main: {
		title: "Ashish godiyal",
		name: "Ashish godiyal",
		email: "ashishgodiyal.333@gmail.com",
		logo: "../logo.jpg",
	},

	socials: {
		twitter: "https://twitter.com/AshishGodiyal6",
		github: "https://github.com/godiyalashish",
		linkedin: "https://www.linkedin.com/in/ashish-godiyal/",
		instagram: "https://www.instagram.com/godiyal__ashish/",
	},

	homepage: {
		title: "Full-stack web developer",
		description:
			"I am a full stack developer with expertise in MERN. I have experience in building scalable, secure and reliable web applications using various frameworks and technologies. I enjoy solving complex problems and learning new skills. I am passionate about creating high-quality code that follows best practices and industry standards. I am always looking for new challenges and opportunities to grow as a developer.",
	},

	about: {
		title: "I’m Ashish Godiyal I live in India.",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Attendance Management",
			description:
				"Full stack Attendance management system with facial recognition system with Raspberry pi.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "#",
		},

		{
			title: "Food App",
			description:
				"Created highly scalable food-app from scratch using parcel as bundler, redux as a state Mangement library.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/godiyalashish/foodvilla",
		},

		{
			title: "Project 3",
			description:
				"Implemented some of functionality of youtube using Youtube’s API, implemented debouncing in search suggestions.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/html/html.png",
			linkText: "View Project",
			link: "https://github.com/godiyalashish/videoApp",
		},

		{
			title: "Reels Clone",
			description:
				"Implemented Reels section of instagram using React as frontend and firebase as backend.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/godiyalashish/Instagram-Reels-clone",
		},
	],
};

export default INFO;
