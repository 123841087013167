const experience = [
	{
		company: "Innover Labs",
        position:'Full stack developer',
		start: "Aug 2023",
		end: null,
		description: `Started project from scratch, managed both fronend and backend, managed other interns 
        helped them to clearly understand project requirement and already coded bits.`,
        techUsed: ['HTML', 'CSS', 'JavaScript', 'React.js', 'Material UI', 'Koa.js', 'Strapi', 'Mongodb', 'Nodejs','python', 'Linux', 'docker']
	},
    {
        company:"Cerebry",
        position:"Junior content developer",
        start:"Jan 2023",
        end:"Jun 2023",
        description:`Coded for dynamic question for IMS client, Fixed Bugs in arabic questions template.
        Collaborated with Frontend team  fixing the bugs, and made changes to the existing ui.`,
        techUsed:['LATEX', 'Python', 'Preact', 'Javascript']
    },
    {
        company:"Edusaint",
        position:"Software developer intern",
        start:"Jan 2022",
        end:"Feb 2022",
        description:`Fixed major and minor issues on the web portal.
        Added new functionalities to the existing system on both frontend and backend`,
        techUsed:['HTML', 'CSS', 'JavsScript', 'PHP', 'MySQL', 'CodeIgniter']
    }
];


export default experience